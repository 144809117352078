<template>
    <div>
        <input type="file" @change="handleFileChange" />
        <el-button @click="handleUpload">上传</el-button>
    </div>
</template>

<script>
import { uploadFileupload, uploadFilemerge } from "@/api";
export default {
    data() {
        return {
            container: {
                file: null,
            },
            data: [],
            fileListLong: "",
            fileSize: "",
            SIZE: 10 * 1024 * 1024,
            timers: ''
        };
    },
    mounted() {
        if (this.$route.query.Token) {
            localStorage.setItem('Token', this.$route.query.Token)
        }
    },
    methods: {
        handleFileChange(e) {
            const [file] = e.target.files;
            if (!file) return;
            this.fileSize = file.size;
            this.container.file = file;
        },
        // ⽣成⽂件切⽚·
        createFileChunk(file, size = this.SIZE) {
            const fileChunkList = [];
            let cur = 0;
            let filenowsize = file.size;
            while (cur < file.size) {
                console.log(file.size, "file.size", size, "size");

                if (filenowsize > size) {
                    filenowsize = filenowsize - size;
                    fileChunkList.push({ file: file.slice(cur, cur + size), size: size });
                } else {
                    fileChunkList.push({
                        file: file.slice(cur, cur + size),
                        size: filenowsize,
                    });
                }
                console.log(filenowsize);
                cur += size;
            }
            return fileChunkList;
        },
        blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    resolve(e.target.result);
                };
                // readAsDataURL
                fileReader.readAsDataURL(blob);
                fileReader.onerror = () => {
                    reject(new Error("blobToBase64 error"));
                };
            });
        },
        // 上传切⽚
        async uploadChunks() {
            const requestList = this.data.map(
                ({ chunk, hash, index, size, length, timers }) => {
                    let formData = {};
                    formData.shard = this.blobToBase64(chunk);
                    formData.fileKey = hash;
                    formData.name = this.container.file.name;
                    formData.shardIndex = index;
                    formData.shardSize = size;
                    formData.fileUse = timers
                    formData.suffix = index
                    formData.shardTotal = length;
                    // const formData = new FormData();
                    // formData.append("file", chunk);
                    // formData.append("hash", hash);
                    // formData.append("filename", this.container.file.name);
                    return { formData };
                }
            ).map(async ({ formData }) => {
                formData.shard = await formData.shard
                await uploadFileupload(formData).then(res => {
                    console.log(formData);
                })
            }

            );
            // 并发请求
            await Promise.all(requestList);
            // this.fileListLong = requestList.length;
            // 合并切⽚
            // await this.mergeRequest();
        },
        async mergeRequest() {
            uploadFilemerge(this.timers).then(res => {
                console.log(res);
            })
            // await this.request({
            //     url: "http://localhost:8080/file/merge",
            //     headers: {
            //         "content-type": "application/json"
            //     },
            //     data: JSON.stringify({
            //         fileSize: this.fileSize,
            //         fileNum: this.fileListLong,
            //         filename: this.container.file.name
            //     })
            // });
        },
        generateMixed(n) {
            var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
                'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
            var res = "";
            for (var i = 0; i < n; i++) {
                var id = Math.floor(Math.random() * 36);
                res += chars[id];
            }
            return res;
        },
        async handleUpload() {
            if (!this.container.file) return;
            this.timers = this.generateMixed(6) + new Date().getTime()
            const fileChunkList = this.createFileChunk(this.container.file);
            this.data = fileChunkList.map(({ file, size }, index) => ({
                chunk: file,
                // ⽂件名 + 数组下标
                hash: this.container.file.name,
                index: index + 1,
                size,
                length: fileChunkList.length,
                timers: this.timers,
            }));
            await this.uploadChunks();
        },
        request({ url, method = "post", data, headers = {}, requestList }) {
            return new Promise((resolve) => {
                const xhr = new XMLHttpRequest();
                xhr.open(method, url);
                Object.keys(headers).forEach((key) =>
                    xhr.setRequestHeader(key, headers[key])
                );
                xhr.send(data);
                xhr.onload = (e) => {
                    resolve({
                        data: e.target.response,
                    });
                };
            });
        },
    },
};
</script>

<style></style>